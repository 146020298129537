import OrderCard from './OrderCard';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useOrdersContext } from './userOrdersContext';

export const Orders = () => {
  const navigate = useNavigate();
  const { filteredOrders } = useOrdersContext();

  return (
    <OrderGrid>
      {filteredOrders.map((order) => (
        <Card key={order.orderNumber}>
          <OrderCard
            order={order}
            onClick={() => navigate(`/orders/${order.orderNumber}`)}
          />
        </Card>
      ))}
    </OrderGrid>
  );
};

const Card = styled.div`
  margin: 0.5rem;
  flex: 0 0 20%;
`;

const OrderGrid = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
`;
