import styled from 'styled-components';
import { GREEN } from '../colors';
import { styled as muistyled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  orderNumber: string;
  greenhouse: { name: string; image: string } | undefined;
  backToOrderNumber: () => void;
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  return (
    <BreadCrumbs>
      <BreadCrumbLinkItem to={'/orders'}>Order Overview</BreadCrumbLinkItem> /
      <BreadCrumbLinkItem
        to={`/orders/${props.orderNumber}`}
        onClick={props.backToOrderNumber}
      >
        {props.orderNumber}
      </BreadCrumbLinkItem>
      {props.greenhouse !== undefined ? <> / {props.greenhouse.name}</> : <></>}
    </BreadCrumbs>
  );
};

const BreadCrumbs = styled.span`
  color: ${GREEN};
  margin-top: 1rem;
  font-size: xx-large;
  align-items: center;
  justify-content: center;
`;

const BreadCrumbLinkItem = muistyled(Link)(() => ({
  color: `${GREEN}`,
  fontSize: 'xx-large',
}));
