import { TablePositionDto } from './orderDetail.types';
import { Card, CardContent } from '@mui/material';
import { GREEN } from '../colors';
import { styled as muistyled } from '@mui/material/styles';
import { BarcodeExpertView } from './BarcodeExpertView';
import styled from 'styled-components';

export const ChamberExpertView = ({
  name,
  tables,
}: {
  name: string;
  tables: TablePositionDto[];
}) => {
  return (
    <Card
      sx={{
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        outline: `0.1rem solid ${GREEN}`,
        paddingBottom: '0px',
      }}
    >
      <Content>
        {name}
        <TableColumn>
          {tables.map((table, idx) => (
            <div key={idx}>
              <TableName>{table.tableName}</TableName>
              <BarcodeExpertView barcodes={table.barcodes} />
            </div>
          ))}
        </TableColumn>
      </Content>
    </Card>
  );
};

const Content = muistyled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const TableColumn = muistyled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
}));

const TableName = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
