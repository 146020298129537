import { ReactElement, useEffect, useState } from 'react';
import useScanDetection from './scanDetection';
import { Button } from '@mui/material';
import { isLogged, login, rfidLogin } from '../KeyCloakService';
import { useNavigate } from 'react-router';
import LoginModal from './LoginModal';
import logo from '../assets/rfid_logo.jpg';
import styled from 'styled-components';

const LandingPage = (): ReactElement => {
  const navigate = useNavigate();
  const [barcodeString, setBarcodeString] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);

  useScanDetection({
    onComplete: setBarcodeString,
    averageWaitTime: 1000,
  });

  useEffect(() => {
    document.getElementById('barcodeString')?.focus();
  }, []);

  useEffect(() => {
    if (!showLoginModal && barcodeString && barcodeString !== '') {
      rfidLogin({ token: barcodeString }).then((r) => {
        if (r) {
          navigate('/orders');
        }
      });
    }
  }, [barcodeString]);

  useEffect(() => {
    if (!showLoginModal && isLogged()) {
      navigate('/orders');
    }
  }, [showLoginModal]);

  const handleSubmit = (username: string, password: string) => {
    login(username, password).then((isLoggedIn) => {
      if (isLoggedIn) {
        setShowLoginModal(false);
      } else {
        setIsLoginFailed(true);
      }
    });
  };

  return (
    <>
      <RfidLoginImage src={logo} alt="Logo" />
      <input
        id={'barcodeString'}
        value={barcodeString}
        type="hidden"
        readOnly={true}
        onBlur={() =>
          setTimeout(() => {
            if (!showLoginModal) {
              document.getElementById('barcodeString')?.focus();
            }
          }, 500)
        }
      />
      <KeycloakLoginButton>
        <Button onClick={() => setShowLoginModal(true)}>Keycloak Login</Button>
        <LoginModal
          isVisible={showLoginModal}
          handleClose={() => {
            setShowLoginModal(false);
            document.getElementById('barcodeString')?.focus();
          }}
          handleSubmit={handleSubmit}
          isLoginFailed={isLoginFailed}
        />
      </KeycloakLoginButton>
    </>
  );
};

const RfidLoginImage = styled.img`
  margin-top: 1rem;
`;

const KeycloakLoginButton = styled.div`
  margin-top: 1rem;
`;

export default LandingPage;
