import { GreenhousePositionDto, HarvestableStatus } from './orderDetail.types';
import { Card, CardContent } from '@mui/material';
import { GREEN } from '../colors';
import { styled as muistyled } from '@mui/material/styles';
import styled from 'styled-components';
import { ChamberExpertView } from './ChamberExpertView';

export const GreenhouseExpertView = ({
  greenhouse,
  onClick,
}: {
  greenhouse: GreenhousePositionDto;
  onClick: (greenhouse: GreenhousePositionDto) => void;
}) => {
  return (
    <Card
      sx={{
        padding: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        outline: `0.1rem solid ${GREEN}`,
        marginBottom: '2rem',
        minWidth: '100%',
      }}
    >
      <Content>
        <Name>{greenhouse.greenhouseName}</Name>
        <ChamberDirection>
          {greenhouse.chambers.map((chamber, idx) => (
            <ChamberBorder
              key={idx}
              status={chamber.status}
              onClick={() => onClick(greenhouse)}
            >
              <ChamberExpertView
                tables={chamber.tables}
                name={chamber.chamberName}
              />
            </ChamberBorder>
          ))}
        </ChamberDirection>
      </Content>
    </Card>
  );
};

const Content = muistyled(CardContent)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const ChamberDirection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  margin-top: 2rem;
  width: 50%;
`;

const ChamberBorder = styled.div<{ status: HarvestableStatus }>`
  margin: 1rem;
  outline: 0.3rem solid
    ${({ status }) =>
      status === HarvestableStatus.HARVESTABLE
        ? 'yellow'
        : status === HarvestableStatus.NORMAL
        ? 'green'
        : 'green'};
  border: ${({ status }) =>
    status === HarvestableStatus.MIXED ? '0.3rem solid yellow' : '0'};
  cursor: pointer;
`;
