import styled from 'styled-components';

export const BarcodeExpertView = ({ barcodes }: { barcodes: string[] }) => {
  return (
    <List>
      {barcodes.map((barcode, idx) => (
        <li key={idx}>{barcode}</li>
      ))}
    </List>
  );
};

const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 15px;
  margin-block-start: 0px;
`;
