import { OrderDetailRepository } from './OrderDetailRepository';
import { GreenhousePositionDto } from './orderDetail.types';
import axios from 'axios';

export class HttpOrderDetailRepository implements OrderDetailRepository {
  public async fetchByOrderNumber(
    orderId: number
  ): Promise<GreenhousePositionDto[]> {
    return (
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}/spa/api/order/${orderId}/history`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
    ).data;
  }
}
