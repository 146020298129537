import { createTheme } from '@mui/material';

export const mpipzTheme = createTheme({
  palette: {
    primary: {
      main: '#4f9a94',
    },
    secondary: {
      main: '#80cbc4',
    },
    background: {
      default: '#fff',
    },
  },
});
