import { MenuItem, Select } from '@mui/material';
import { Sorting } from './sorting';
import { useOrdersContext } from './userOrdersContext';

export const OrderSort = () => {
  const { sorting, setSorting } = useOrdersContext();

  return (
    <>
      <Select
        value={sorting}
        onChange={(event) => setSorting(event.target.value as Sorting)}
      >
        <MenuItem value={Sorting.ORDER_NUMBER_DESC}>
          {Sorting.ORDER_NUMBER_DESC}
        </MenuItem>
        <MenuItem value={Sorting.ORDERER_NAME_ASC}>
          {Sorting.ORDERER_NAME_ASC}
        </MenuItem>
      </Select>
    </>
  );
};
