import { ReactElement } from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';
import { Logout } from '@mui/icons-material';
import { logout } from '../KeyCloakService';
import { useNavigate } from 'react-router';

type MenueProps = {
  isVisible: boolean;
  changeState: (isVisible: boolean) => void;
};

const Menue = (props: MenueProps): ReactElement => {
  const navigate = useNavigate();

  const toggle = (isVisible: boolean) => (): void => {
    props.changeState(isVisible);
  };

  const doLogout = () =>
    logout().then(() => {
      props.changeState(false);
      navigate('/');
    });

  return (
    <SwipeableDrawer
      anchor={'left'}
      open={props.isVisible}
      onOpen={toggle(true)}
      onClose={toggle(false)}
    >
      <List sx={{ width: 250 }}>
        <ListItem key={'startpage'} onClick={() => navigate('/')}>
          <ListItemText primary={'Start page'} sx={{ fontSize: 30 }} />
        </ListItem>
      </List>
      <div
        style={{
          minHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Divider />
        <List sx={{ width: 250, marginBottom: '30px' }}>
          <ListItem key={'logout'} onClick={doLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText
              primary={'Logout'}
              sx={{ fontSize: 30, cursor: 'pointer' }}
            />
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
};

export default Menue;
