import { ChangeEvent, ReactElement, useState } from 'react';
import { Alert, Box, Button, Modal } from '@mui/material';
import Grid from '@mui/material/Grid';

type LoginModalProps = {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: (username: string, password: string) => void;
  isLoginFailed: boolean;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  height: '30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const LoginModal = (props: LoginModalProps): ReactElement => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);
  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value);

  return (
    <Modal
      open={props.isVisible}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.handleSubmit(username, password);
        }}
      >
        <Box sx={style}>
          <Grid container width={'100%'} height={'100%'}>
            <Grid item xs={12}>
              {props.isLoginFailed && (
                <Alert severity="error">
                  Login failed! Please check your credentials.
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <label>
                Username:
                <input
                  style={{ marginLeft: '1rem' }}
                  type="text"
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </label>
            </Grid>
            <Grid item xs={12}>
              <label>
                Password:
                <input
                  style={{ marginLeft: '1rem' }}
                  type="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </label>
            </Grid>
            <Grid item xs={12}>
              <Button
                type={'submit'}
                onClick={() => props.handleSubmit(username, password)}
                style={{ margin: 'auto', display: 'flex', fontSize: 24 }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

export default LoginModal;
