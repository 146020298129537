interface LogoutForm {
  client_id: string;
  refresh_token: string;
}

interface LoginForm {
  username: string;
  password: string;
  client_id: string;
  grant_type: string;
}

const keycloakTokenEndpoint =
  `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/realms/master/protocol/openid-connect/token`;
const keycloakLogoutEndpoint =
  `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/realms/master/protocol/openid-connect/logout`;
const baseURL = process.env.REACT_APP_BASE_URL;
const clientId = 'greenhouse_terminal';

const urlEncodedFormBody = (details: object): string => {
  const formBodyArray: string[] = [];
  for (const property in details) {
    const encodedKey = encodeURIComponent(property);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const encodedValue = encodeURIComponent(details[property]);
    formBodyArray.push(encodedKey + '=' + encodedValue);
  }
  return formBodyArray.join('&');
};

export const logout = (): Promise<boolean> => {
  const details: LogoutForm = {
    client_id: clientId,
    refresh_token: localStorage.getItem('refresh-token') ?? '',
  };

  return fetch(keycloakLogoutEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: urlEncodedFormBody(details),
  })
    .then((response) => response.status === 204)
    .then((isLogout) => {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');
      return isLogout;
    });
};

export const login = (username: string, password: string): Promise<boolean> => {
  const details: LoginForm = {
    client_id: clientId,
    grant_type: 'password',
    password: password,
    username: username,
  };

  return fetch(keycloakTokenEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: urlEncodedFormBody(details),
  })
    .then((response) => {
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(([res, data]) => {
      if (res === 200) {
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refresh-token', data.refresh_token);
      }
      return res === 200;
    });
};

export const isLogged = () => {
  return localStorage.getItem('token') !== null;
};

export const getFullName = () =>
  parsedJwt()?.given_name + ' ' + parsedJwt()?.family_name;

export const getNFC = () => parsedJwt()?.nfc_token;

type NfcLoginProps = {
  token: string;
};

export const rfidLogin = (props: NfcLoginProps): Promise<boolean> => {
  //Only one user has the term "Shift" in his token. Nobody knows the root cause of this behavior
  return fetch(baseURL + '/spa/login/' + props.token.replace('Shift',''))
    .then((requestedToken) => {
      return requestedToken.json();
    })
    .then((json) => {
      localStorage.setItem('token', json.access_token);
      localStorage.setItem('refresh-token', json.refresh_token);
      return !!json;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parsedJwt(): any | undefined {
  const token = localStorage.getItem('token');
  if (!token) {
    return undefined;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
