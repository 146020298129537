import { useCallback, useEffect, useState } from 'react';

export type UseSearch<Row> = {
  shownRows: Row[];
  currentSearchQuery: CurrentSearchQuery;
  search: (query: string) => void;
  reset: () => void;
};

export type Matcher<Row> = (
  args: [row: Row, queryNormalized: string, queryAsIs: string]
) => boolean;

export const useSearch = <Row>(
  initial: Row[],
  matcher: Matcher<Row>
): UseSearch<Row> => {
  const [allRows, setAllRows] = useState<Row[]>(initial);
  const [shownRows, setShownRows] = useState<Row[]>(initial);
  const [searchQuery, setSearchQuery] = useState<CurrentSearchQuery>(undefined);

  const reset = useCallback(() => setSearchQuery(undefined), []);
  const search = useCallback(
    (query: string) =>
      query.trim().length > 0
        ? setSearchQuery({
            asIs: query,
            normalized: query.trim().toLowerCase(),
          })
        : reset(),
    []
  );

  const applySearch = (search: Row[]) =>
    setShownRows(
      searchQuery
        ? search.filter((row) =>
            matcher([row, searchQuery.normalized, searchQuery.asIs])
          )
        : search
    );

  useEffect(() => {
    setAllRows(initial);
    applySearch(initial);
  }, [initial]);

  useEffect(() => {
    applySearch(allRows);
  }, [searchQuery]);

  return {
    shownRows,
    currentSearchQuery: searchQuery,
    search,
    reset,
  };
};

type CurrentSearchQuery =
  | {
      asIs: string;
      normalized: string;
    }
  | undefined;
