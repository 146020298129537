import { ReactElement } from 'react';

type MarkProps = {
  onClick?: () => void;
  isHall: boolean;
  isHarvestable: boolean;
};

const ChamberMark = (props: MarkProps): ReactElement => {
  return (
    <svg
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      onClick={props.onClick}
    >
      <rect
        width={props.isHall ? 60 : 30}
        height="25"
        stroke={props.isHarvestable ? 'yellow' : 'green'}
        strokeWidth="3"
        fill="transparent"
      />
      Sorry, your browser does not support inline SVG.
    </svg>
  );
};

export default ChamberMark;
