import 'mapbox-gl/dist/mapbox-gl.css';
import { Map, MapProvider, Marker } from '@vinctus/react-mapbox';
import { ReactElement } from 'react';
import MarkMixedPlants from './markMixedPlants';
import {
  GreenhousePositionDto,
  HarvestableStatus,
} from '../orderDetail/orderDetail.types';
import MarkNormalPlants from './markNormalPlants';
import MarkHarvestablePlants from './markHarvestablePlants';

const accessToken =
  'pk.eyJ1IjoibGlzZS1tcGlweiIsImEiOiJjbDR4bzFmcnkxcjIzM21tbDFqbjlubzJhIn0.Zn0GmWUVjYc2oHZ5CD_ktg';

interface MpipzMapProps {
  greenhouses: GreenhousePositionDto[];
  onPlantSelected: (greenhouse: GreenhousePositionDto) => void;
}

const MpipzMap = (props: MpipzMapProps): ReactElement => {
  const latMpipz = 50.95743419673335;
  const lngMpipz = 6.85925200531073;
  const zoom = 16;

  return (
    <MapProvider>
      <Map
        style={{ width: '1000px', height: '800px' }}
        longitude={lngMpipz}
        latitude={latMpipz}
        zoom={zoom}
        accessToken={accessToken}
      />
      {props.greenhouses.map((greenhouse, index) => (
        <Marker
          longitude={greenhouse.greenhousePositionLong}
          latitude={greenhouse.greenhousePositionLat}
          key={index}
        >
          {getHarvestableStatusInGreenhouse(greenhouse) ===
            HarvestableStatus.MIXED && (
            <MarkMixedPlants
              onClick={() => {
                props.onPlantSelected(greenhouse);
              }}
            />
          )}
          {getHarvestableStatusInGreenhouse(greenhouse) ===
            HarvestableStatus.HARVESTABLE && (
            <MarkHarvestablePlants
              onClick={() => {
                props.onPlantSelected(greenhouse);
              }}
            />
          )}
          {getHarvestableStatusInGreenhouse(greenhouse) ===
            HarvestableStatus.NORMAL && (
            <MarkNormalPlants
              onClick={() => {
                props.onPlantSelected(greenhouse);
              }}
            />
          )}
        </Marker>
      ))}
    </MapProvider>
  );
};

const getHarvestableStatusInGreenhouse = (
  greenhouse: GreenhousePositionDto
) => {
  const allStatus = greenhouse.chambers.map((c) => c.status);
  const allNormal = allStatus.every((v) => v === HarvestableStatus.NORMAL);
  const allHarvestable = allStatus.every(
    (v) => v === HarvestableStatus.HARVESTABLE
  );

  if (allNormal) return HarvestableStatus.NORMAL;
  if (allHarvestable) return HarvestableStatus.HARVESTABLE;
  return HarvestableStatus.MIXED;
};

export default MpipzMap;
