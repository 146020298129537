import { ReactElement } from 'react';

type MarkProps = {
  onClick?: () => void;
};

const MarkHarvestablePlants = (props: MarkProps): ReactElement => {
  return (
    <svg
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      width="20"
      height="20"
      onClick={props.onClick}
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        stroke="yellow"
        strokeWidth="2"
        fill="transparent"
      />
      Sorry, your browser does not support inline SVG.
    </svg>
  );
};

export default MarkHarvestablePlants;
