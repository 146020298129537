import { ReactElement } from 'react';
import OrderCardSearch from './OrderCardSearch';
import { OrdersProvider, useOrders } from './userOrdersContext';
import { OrderSort } from './OrderSort';
import { Orders } from './Orders';
import styled from 'styled-components';

const OrderCardOverview = (): ReactElement => {
  return (
    <OrdersProvider value={useOrders()}>
      <InOneRow>
        <OrderCardSearch />
        <OrderSort />
      </InOneRow>
      <Orders />
    </OrdersProvider>
  );
};

const InOneRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  gap: 2rem;
`;

export default OrderCardOverview;
