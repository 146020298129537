import { GreenhousePositionDto } from './orderDetail.types';
import styled from 'styled-components';
import { GreenhouseExpertView } from './GreenhouseExpertView';

export interface DetailsListProps {
  greenhouses: GreenhousePositionDto[];
  onClick: (greenhouse: GreenhousePositionDto) => void;
}

export const DetailsList = (props: DetailsListProps) => {
  return (
    <List>
      {props.greenhouses.map((greenhouse, idx) => (
        <GreenhouseExpertView
          greenhouse={greenhouse}
          key={idx}
          onClick={props.onClick}
        />
      ))}
    </List>
  );
};

const List = styled.div`
  margin-top: 1rem;
  margin-left: 2rem;
  min-width: 100%;
`;
