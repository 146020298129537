import { Moment } from 'moment';

export interface Order {
  orderNumber: number;
  orderer: string;
  department: string;
  plantSpecies: string;
  cultivationStart: Moment;
  harvestable: boolean;
}

export function orderToString(order: Order): string {
  return (
    order.orderNumber.toString() +
    order.orderer +
    order.department +
    order.plantSpecies +
    order.cultivationStart +
    (order.harvestable ? 'Harvestable' : '')
  );
}
