import { ReactElement } from 'react';
import { Card, CardContent } from '@mui/material';
import { Order } from './order.types';
import moment from 'moment';
import { GREEN } from '../colors';
import styled from 'styled-components';

type OrderCardProps = {
  order: Order;
  onClick: (orderNumber: number) => void;
};

const OrderCard = (props: OrderCardProps): ReactElement => {
  return (
    <Card
      onClick={() => props.onClick(props.order.orderNumber)}
      sx={{
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        outline: `0.1rem solid ${GREEN}`,
        padding: '10px',
        marginBottom: 2,
      }}
    >
      <CardContent>
        <Species>{props.order.plantSpecies}</Species>
        <OrderNumber>Order {props.order.orderNumber}</OrderNumber>
        <Orderer>
          Orderer: {props.order.orderer} <i>({props.order.department})</i>
        </Orderer>
        <CultivationStart>
          Cultivation start at{' '}
          {moment(props.order.cultivationStart).format('yyyy-MM-DD')}
        </CultivationStart>
        <Harvestable>
          {props.order.harvestable ? 'Harvestable' : ''}
        </Harvestable>
      </CardContent>
    </Card>
  );
};

const Species = styled.div`
  color: black;
`;

const CultivationStart = styled.div`
  color: black;
`;

const Harvestable = styled.div`
  color: black;
  margin-top: 0.5rem;
`;

const OrderNumber = styled.div`
  font-size: xx-large;
`;

const Orderer = styled.div`
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0.5rem;
`;

export default OrderCard;
