import React from 'react';
import './App.css';
import MpipzAppBar from './MpipzAppBar';
import OrderCardOverview from './orderCard/OrderCardOverview';
import { Route, Routes } from 'react-router-dom';
import OrderDetail from './orderDetail/OrderDetail';
import LandingPage from './landingPage/LandingPage';
import ProtectedRoute from './ProtectedRoute';
import { HttpOrderDetailRepository } from './orderDetail/HttpOrderDetailRepository';

const orderDetailRepository = new HttpOrderDetailRepository();

function App() {
  return (
    <>
      <MpipzAppBar />
      <Routes>
        <Route path={'/'} element={<LandingPage />} />
        <Route
          path={'/orders'}
          element={
            <ProtectedRoute>
              <OrderCardOverview />
            </ProtectedRoute>
          }
        />
        <Route
          path={'/orders/:orderNumber'}
          element={
            <ProtectedRoute>
              <OrderDetail orderDetailRepository={orderDetailRepository} />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
