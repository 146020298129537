import { ReactElement, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useOrdersContext } from './userOrdersContext';
import { Matcher, useSearch } from './useSearch';
import { Order, orderToString } from './order.types';

const OrderCardSearch = (): ReactElement => {
  const { originalOrders, setFilteredOrders } = useOrdersContext();
  const { shownRows, currentSearchQuery, search } = useSearch(
    originalOrders,
    matchOrderNaviItems
  );

  useEffect(() => {
    setFilteredOrders(shownRows);
  }, [shownRows]);

  return (
    <TextField
      sx={{ width: '40%' }}
      placeholder="Search…"
      inputProps={{ 'aria-label': 'search' }}
      onChange={(e) => search(e.target.value)}
      value={currentSearchQuery?.asIs || ''}
    />
  );
};

const matchOrderNaviItems: Matcher<Order> = ([row, query]): boolean =>
  orderToString(row).toLowerCase().includes(query);

export default OrderCardSearch;
