import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Order } from './order.types';
import { HttpOrderRepository } from './HttpOrderRepository';
import { Sorting } from './sorting';

const OrdersContext = createContext<UseOrdersReturn>({
  originalOrders: [],
  filteredOrders: [],
  setFilteredOrders: () => undefined,
  sorting: Sorting.ORDER_NUMBER_DESC,
  setSorting: () => undefined,
});

export type UseOrdersReturn = {
  originalOrders: Order[];
  filteredOrders: Order[];
  setFilteredOrders: (orders: Order[]) => void;
  sorting: Sorting;
  setSorting: (sorting: Sorting) => void;
};

export const OrdersProvider = ({
  value,
  children,
}: PropsWithChildren<{
  value: UseOrdersReturn;
}>): ReactElement => (
  <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
);

export const useOrdersContext = () =>
  useContext(OrdersContext) as UseOrdersReturn;

export const useOrders = (): UseOrdersReturn => {
  const [originalOrders, setOriginalOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [sorting, setSorting] = useState<Sorting>(Sorting.ORDER_NUMBER_DESC);

  useEffect(() => {
    new HttpOrderRepository().fetchAll().then((orders) => {
      setOriginalOrders(orders);
      setFilteredOrders(orders);
    });
  }, []);

  const getOrders = useMemo(() => {
    if (sorting === Sorting.ORDER_NUMBER_DESC) {
      setFilteredOrders(
        filteredOrders.sort((a, b) => b.orderNumber - a.orderNumber)
      );
    } else if (sorting === Sorting.ORDERER_NAME_ASC) {
      setFilteredOrders(
        filteredOrders.sort((a, b) =>
          a.orderer
            .toLocaleLowerCase()
            .localeCompare(b.orderer.toLocaleLowerCase())
        )
      );
    }
    return filteredOrders;
  }, [filteredOrders, sorting]);

  return {
    originalOrders,
    filteredOrders: getOrders,
    setFilteredOrders,
    sorting,
    setSorting,
  };
};
