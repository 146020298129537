export interface GreenhousePositionDto {
  orderNumber: number;
  greenhouseName: string;
  greenhousePositionLat: number;
  greenhousePositionLong: number;
  chambers: ChamberPositionDto[];
}

export interface ChamberPositionDto {
  chamberName: string;
  chamberPosX: number;
  chamberPosY: number;
  status: HarvestableStatus;
  tables: TablePositionDto[];
}

export interface TablePositionDto {
  tableName: string;
  barcodes: string[];
}

export enum HarvestableStatus {
  NORMAL = 'NORMAL',
  HARVESTABLE = 'HARVESTABLE',
  MIXED = 'MIXED',
}
