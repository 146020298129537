import { ReactElement, useState } from 'react';
import { Box, IconButton, Toolbar } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Menue from './menue/Menue';
import { getFullName, isLogged } from './KeyCloakService';

const MpipzAppBar = (): ReactElement => {
  const [isMenueVisible, setIsMenueVisible] = useState(false);

  const changeState = (isVisible: boolean) => setIsMenueVisible(isVisible);
  const openMenue = () => changeState(true);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: '#eee', color: '#777' }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={openMenue}
          >
            <MenuIcon />
          </IconButton>
          <Menue isVisible={isMenueVisible} changeState={changeState} />
          {isLogged() && <div>Hello, {getFullName()}</div>}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MpipzAppBar;
