import { ReactElement } from 'react';

type MarkProps = {
  onClick?: () => void;
};

const MarkMixedPlants = (props: MarkProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
      width="20"
      height="20"
      viewBox="-10 -10 220 220"
      onClick={props.onClick}
    >
      <defs>
        <linearGradient
          id="redyel"
          gradientUnits="objectBoundingBox"
          x1="0"
          y1="0"
          x2="1"
          y2="1"
        >
          <stop offset="0%" stopColor="yellow" />
          <stop offset="100%" stopColor="yellow" />
        </linearGradient>
        <linearGradient
          id="yelgre"
          gradientUnits="objectBoundingBox"
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="0%" stopColor="green" />
          <stop offset="100%" stopColor="green" />
        </linearGradient>
      </defs>
      <g fill="none" strokeWidth="15" transform="translate(100,100)">
        <path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke="url(#redyel)" />
        <path d="M 86.6,-50 A 100,100 0 0,1 86.6,50" stroke="url(#redyel)" />
        <path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke="url(#redyel)" />
        <path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke="url(#yelgre)" />
        <path d="M -86.6,50 A 100,100 0 0,1 -86.6,-50" stroke="url(#yelgre)" />
        <path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke="url(#yelgre)" />
      </g>
      Sorry, your browser does not support inline SVG.
    </svg>
  );
};

export default MarkMixedPlants;
