import { OrderRepository } from './OrderRepository';
import { Order } from './order.types';
import axios from 'axios';

export class HttpOrderRepository implements OrderRepository {
  public async fetchAll(): Promise<Order[]> {
    return (
      await axios.get(`${process.env.REACT_APP_BASE_URL}/spa/api/order`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
    ).data;
  }
}
