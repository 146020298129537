import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { OrderDetailRepository } from './OrderDetailRepository';
import MpipzMap from '../map/MpipzMap';
import styled from 'styled-components';
import greenhouse1 from '../assets/Greenhouse1und2.svg';
import greenhouse4 from '../assets/greenhouse_4.png';
import greenhouse5 from '../assets/greenhouse_5.png';
import hall14 from '../assets/Hall_14.svg';
import hall10 from '../assets/Hall_10.svg';
import no_image_found from '../assets/no_image_found.jpg';
import { Breadcrumb } from './Breadcrumb';
import { DetailsList } from './DetailsList';
import ChamberMark from '../map/chamberMark';
import { GreenhousePositionDto, HarvestableStatus } from './orderDetail.types';

interface OrderDetailProps {
  orderDetailRepository: OrderDetailRepository;
}

const locationsWithImage = [
  { name: 'Greenhouse 1', image: greenhouse1 },
  { name: 'Greenhouse 2', image: greenhouse1 },
  { name: 'Greenhouse 4', image: greenhouse4 },
  { name: 'Greenhouse 5', image: greenhouse5 },
  { name: 'Hall 14', image: hall14 },
  { name: 'Hall 10', image: hall10 },
];

const OrderDetail = (props: OrderDetailProps): ReactElement => {
  const params = useParams();
  const orderNumber = params.orderNumber || '';

  const [details, setDetails] = useState<GreenhousePositionDto[] | null>(null);
  const [selectedGreenhouse, setSelectedGreenhouse] =
    useState<GreenhousePositionDto | null>(null);
  const [greenhouseImage, setGreenhouseImage] = useState<
    | {
        name: string;
        image: string;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    props.orderDetailRepository
      .fetchByOrderNumber(Number(orderNumber))
      .then((fetchedOrder) => setDetails(fetchedOrder));
  }, []);

  const onGreenhouseSelected = (greenhouse: GreenhousePositionDto) => {
    setSelectedGreenhouse(greenhouse);
    setGreenhouseImage(
      locationsWithImage.filter(
        (x) => x.name === greenhouse.greenhouseName
      )[0] ?? {
        name: 'No image found',
        image: no_image_found,
      }
    );
  };

  if (!details) {
    return <div>Loading...</div>;
  }

  return (
    <DetailPage>
      <Breadcrumb
        orderNumber={orderNumber}
        greenhouse={greenhouseImage}
        backToOrderNumber={() => {
          setGreenhouseImage(undefined);
          setSelectedGreenhouse(null);
        }}
      />
      <Content>
        <GreenhousePlace>
          <Map
            isNoPlantSelected={selectedGreenhouse === null}
            greenhouses={details}
            onPlantSelected={onGreenhouseSelected}
          />
          <GreenhouseImageWithFixedSize
            greenhouseImage={greenhouseImage?.image}
            greenhouse={selectedGreenhouse ?? undefined}
          />
          {selectedGreenhouse !== null && greenhouseImage === undefined ? (
            <>No image found</>
          ) : (
            <></>
          )}
        </GreenhousePlace>
        <GreenhousePlaceDetails>
          <DetailsList greenhouses={details} onClick={onGreenhouseSelected} />
        </GreenhousePlaceDetails>
      </Content>
    </DetailPage>
  );
};

const DetailPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const GreenhousePlace = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
`;

const GreenhousePlaceDetails = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Map = ({
  isNoPlantSelected,
  onPlantSelected,
  greenhouses,
}: {
  isNoPlantSelected: boolean;
  greenhouses: GreenhousePositionDto[];
  onPlantSelected: (plant: GreenhousePositionDto) => void;
}) => {
  if (isNoPlantSelected) {
    return (
      <MpipzMap greenhouses={greenhouses} onPlantSelected={onPlantSelected} />
    );
  }

  return <></>;
};

const GreenhouseImageWithFixedSize = ({
  greenhouseImage,
  greenhouse,
}: {
  greenhouseImage: string | undefined;
  greenhouse?: GreenhousePositionDto;
}) => {
  if (greenhouseImage) {
    return (
      <>
        <img
          id={'greenhouseImage'}
          src={greenhouseImage}
          style={{
            width: '1000px',
          }}
          alt={'Greenhouse image not found'}
        />
        <div
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            position: 'relative',
            display: 'flex',
            //aspectRatio: calculateAspectRatio().toString(),
          }}
        >
          {greenhouse?.chambers.map((chamber, idxChamber) => (
            <svg
              key={idxChamber}
              style={{
                position: 'absolute',
                left: chamber.chamberPosX,
                top: chamber.chamberPosY,
                width: `${
                  greenhouse.greenhouseName.startsWith('Hall') ? '60px' : '30px'
                }`,
                height: '30px',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <ChamberMark
                isHarvestable={chamber.status === HarvestableStatus.HARVESTABLE}
                isHall={greenhouse.greenhouseName.startsWith('Hall')}
              />
            </svg>
          ))}
        </div>
      </>
    );
  }

  return <></>;
};

export default OrderDetail;
