import { ReactElement, useEffect, useState } from 'react';
import { isLogged } from './KeyCloakService';

type ProtectedRouteProps = {
  children: ReactElement;
};

const ProtectedRoute = ({ children }: ProtectedRouteProps): ReactElement => {
  const [eingeloggt, setIsLogged] = useState(false);

  useEffect(() => {
    setIsLogged(isLogged());
  }, []);

  return eingeloggt ? children : <></>;
};

export default ProtectedRoute;
